import { useEffect } from "react";
import { CALL_API_METHODS } from "repoV2/constants/apis";
import { WEEK_IN_MILLISECONDS } from "repoV2/features/Common/modules/DateTime/modules/Time/Time.constants";
import { callApi } from "../api/callApi";
import { logError } from "../error/error";
import {
    getLocalStorageItem,
    getSessionStorageItem,
    setLocalStorageItem,
    setSessionStorageItem,
} from "../storage/getterAndSetters";
import { LOCAL_STORAGE_KEYS } from "../../../constants/common/storage/localStorage";
import { SESSION_STORAGE_KEYS } from "../../../constants/common/storage/sessionStorage";

/**
 * see `callPostExlyAnalytics` to understand diff b/w `UserIp` and `UserIpDetailsForAnalyticsInfo`
 */
export const getUserIp = (): string | undefined =>
    getSessionStorageItem(SESSION_STORAGE_KEYS.USER_IP);

export const setUserIp = (value?: string) =>
    setSessionStorageItem(SESSION_STORAGE_KEYS.USER_IP, value);

export type IUserIpDetails = {
    data: {
        country: string | null;
        city: string | null;
        state: string | null;
    };
    metaData: {
        validTill: number | null;
    };
};

export const default_user_ip_details_for_analytics_info: IUserIpDetails = {
    data: {
        country: null,
        city: null,
        state: null,
    },
    metaData: {
        validTill: null,
    },
};

export const getUserIpDetailsForAnalyticsInfo = (): IUserIpDetails =>
    getLocalStorageItem(
        LOCAL_STORAGE_KEYS.USER_IP_DETAILS_FOR_ANALYTICS_INFO
    ) || JSON.parse(JSON.stringify(default_user_ip_details_for_analytics_info));

const setUserIpDetailsForAnalyticsInfo = (value: IUserIpDetails) =>
    setLocalStorageItem(
        LOCAL_STORAGE_KEYS.USER_IP_DETAILS_FOR_ANALYTICS_INFO,
        value
    );

/**
 * get api call to fetch user ip details from a free end point https://geolocation-db.com/json/
 */
export const GET_USER_IP_DETAILS_API_URL =
    "https://ip-geo-location.p.rapidapi.com/ip/check";
export const callGetUserIpDetails = () =>
    callApi({
        url: GET_USER_IP_DETAILS_API_URL,
        method: CALL_API_METHODS.GET,
        params: { format: "json" },
        headers: {
            "x-rapidapi-key": process.env.NEXT_PUBLIC_IP_GEOLOCATION_KEY || "",
            "x-rapidapi-host": "ip-geo-location.p.rapidapi.com",
        },
    });

export const useStoreUserIPDetailsForAnalytics = () => {
    const validTill = Date.now() + WEEK_IN_MILLISECONDS;

    /**
     * Backend needs the values as null if they are not assigned
     */
    const userIpDetails: {
        data: {
            country: string | null;
            city: string | null;
            state: string | null;
        };
        metaData: {
            validTill: number | null;
        };
    } = {
        data: {
            country: null,
            city: null,
            state: null,
        },
        metaData: {
            validTill: null,
        },
    };

    /**
     * check if value for validity exisits in local storage or not
     * if valid till value exists then check if difference in milliseconds greater than a week
     * else call the api to get location
     */
    const currentValidTill =
        getUserIpDetailsForAnalyticsInfo().metaData?.validTill;
    const shouldCallApi = currentValidTill
        ? validTill - currentValidTill > WEEK_IN_MILLISECONDS
        : true;

    useEffect(() => {
        if (shouldCallApi) {
            callGetUserIpDetails()
                .then((response: any) => {
                    const { country, city, area } = response?.data;
                    userIpDetails.data.country = country?.name;
                    userIpDetails.data.city = city?.name;
                    userIpDetails.data.state = area?.name;
                    userIpDetails.metaData.validTill = validTill;
                    setUserIpDetailsForAnalyticsInfo(userIpDetails);
                })
                .catch(err => {
                    logError({
                        error: err,
                        occuredAt: "modals/AfterBookingQuestions",
                        when: "calling callGetUserIpDetails",
                        severity: "medium",
                    });
                });
        }
    }, []);
};
