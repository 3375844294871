import { isDateFormatValid } from "@Utils/validation";
import { format, set } from "date-fns";
import React from "react";
import { classNames } from "repoV2/utils/common/render/classNames";
import { DEFAULT_API_PAYLOAD_DATETIME_FORMAT } from "repoV2/features/Common/modules/DateTime/modules/Time/Time.constants";

import { parseDateTime } from "@Utils/parseDateTime";
import styles from "../../../userDetails.module.scss";
import { CUSTOM_QUESTION_TYPES } from "../../../../../../../../repoV2/utils/getInTouchForm/getInTouchForm.constants";
import { ICustomQuestions } from "../ICustomQuestions";

const CalendarQuestion = ({
    item,
    showInputError,
    answers,
    setAnswers,
    userInput,
    htmlId,
}: ICustomQuestions.ICalendarQuestionProps) => {
    const castedUserInput = ((userInput as string) || "").trim();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = format(
            set(new Date(e.target.value), {
                hours: 0,
                minutes: 0,
                seconds: 0,
            }),
            DEFAULT_API_PAYLOAD_DATETIME_FORMAT
        );
        setAnswers({ ...answers, [e.target.name]: value });
    };
    return (
        <div className={classNames(styles.fieldContainer, styles.flexCol)}>
            <input
                name={item.uuid}
                id={htmlId}
                className={classNames(
                    styles.formInput,
                    showInputError &&
                        !isDateFormatValid(castedUserInput?.toString())
                        ? styles.formInputError
                        : false
                )}
                type="date"
                onChange={handleChange}
                value={
                    castedUserInput
                        ? parseDateTime(castedUserInput, "yyyy-MM-dd")
                        : ""
                }
                {...item.validations}
            />
            <div className={styles.fieldError}>
                {showInputError &&
                CUSTOM_QUESTION_TYPES[item.type].validateAnswer(
                    castedUserInput
                ) ? (
                    "Please enter a valid date"
                ) : (
                    <>&nbsp;</>
                )}
            </div>
        </div>
    );
};

export default CalendarQuestion;
